import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'

const StatusBlock = (props) => {

    const [nextCheck, setNextCheck] = useState(props.checkInterval / 1000)

    /* Counter Loop */
    useEffect(() => {
        const counter = setInterval(() => {
            setNextCheck(nextCheck => nextCheck - 1)
        }, 1000)

        return (() => {
            clearInterval(counter)
        })
    }, [nextCheck])

    /* Reset Counter after API response */
    useEffect(() => {
        setNextCheck(() => props.checkInterval / 1000)
    }, [props.lastCheck, props.checkInterval])

    return (
        <div className={`statusblock`}>

            <div className={`row header`}>
                <span className={`name`}>{props.name}</span>

                <span
                    className={`status 
                        ${props.status === 200 ? 'up' :
                            props.status === 0 ? 'unknown' :
                                props.status === 500 ? 'maintenance' : 'down'}`}
                    title={`${props.name} ist derzeit ${props.status === 200 ? 'up' : 'down'}`}
                ></span>
            </div>

            <div className={`row nextCheck`}>
                <span>Nächster Check:</span>
                <span>{nextCheck >= 0 ? ` ${nextCheck} s` : ' 0 s'}</span>
            </div>

            <div className={`row lastCheck`}>
                <span>Letzter Check:</span>
                <Moment format='DD.MM.YYYY HH:mm'>
                    {props.lastCheck}
                </Moment>
            </div>

        </div>
    )
}

StatusBlock.propTypes = {
    loading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    lastCheck: PropTypes.number.isRequired,
    lastResponse: PropTypes.string,
    checkInterval: PropTypes.number.isRequired,
}

export { StatusBlock }
