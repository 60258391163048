import React, { useState, useEffect } from 'react'
import { StatusBlock } from '../StatusBlock/'

const Website = () => {

    const WEBSITE_URL = 'https://www.haffhus.de'

    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(0)
    const [lastCheck, setLastCheck] = useState(Date.now())
    const [lastResponse, setLastResponse] = useState('')
    const [checkInterval, setCheckInterval] = useState(60000)

    const fetchStatus = async () => {
        setLoading(true)

        try {
            const res = await fetch(WEBSITE_URL, { mode: 'no-cors' })
            if (res) {
                setStatus(200)
                setLastResponse(Date.now().toString())
            }
        } catch (err) {
            console.log(err)
            console.error(`Website Status check for ${WEBSITE_URL} failed. Website did not respond.`)
            setStatus(404)
        }

        setLastCheck(Date.now())

        setLoading(false)
    }

    useEffect(() => {
        fetchStatus();
        const initLoop = setInterval(() => {
            fetchStatus();
        }, checkInterval)

        return (() => {
            clearInterval(initLoop)
        })

        // eslint-disable-next-line
    }, [])


    return (
        <StatusBlock
            loading={loading}
            name='Website'
            status={status}
            lastResponse={lastResponse}
            lastCheck={lastCheck}
            checkInterval={checkInterval}
        />
    )
}

export default Website
