import React, { useState, useEffect } from 'react'
import { StatusBlock } from '../StatusBlock/'
import axios from 'axios'

const PublicAPI = () => {

    const PUBLIC_API_URL = 'https://energie.haffhus.de/api/v1/status/1'

    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(0)
    const [lastCheck, setLastCheck] = useState(Date.now())
    const [checkInterval, setCheckInterval] = useState(30000);

    const fetchStatus = async () => {
        setLoading(true)

        try {
            const res = await axios(PUBLIC_API_URL)
            if (res) {
                setStatus(200)
            }
        } catch (err) {
            setStatus(404)
            console.log(err)
        }

        setLastCheck(Date.now())

        setLoading(false);
    }

    useEffect(() => {
        fetchStatus();
        const initLoop = setInterval(() => {
            fetchStatus();
        }, checkInterval)

        return (() => {
            clearInterval(initLoop)
        })

        // eslint-disable-next-line
    }, [])


    return (
        <StatusBlock
            loading={loading}
            name='Public API'
            status={status}
            lastCheck={lastCheck}
            checkInterval={checkInterval}
        />
    )
}

export default PublicAPI
