import React from 'react'
import StatusBlocks from '../components/Blocks/'
import Legend from '../components/Legend'

const Home = () => {
    return (
        <div className={`wrapper home`}>
            <h1>Haffhus Status</h1>
            <StatusBlocks />
            <Legend />
        </div>
    )
}

export default Home
