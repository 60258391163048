import React, { useState, useEffect } from 'react'
import { StatusBlock } from '../StatusBlock/'
import axios from 'axios'
import moment from 'moment'

const LocalAPI = () => {

    const LOCAL_API_URL = 'https://energie.haffhus.de/api/v1/status/1'

    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(0)
    const [data, setData] = useState([])
    const [lastCheck, setLastCheck] = useState(Date.now())
    const [checkInterval, setCheckInterval] = useState(30000);

    const fetchStatus = async () => {
        setLoading(true)

        try {
            const res = await axios(LOCAL_API_URL)
            setData(res.data);
            checkStatus(res.data);
        } catch (err) {
            console.log(err)
        }

        setLastCheck(Date.now())

        setLoading(false);
    }

    useEffect(() => {
        fetchStatus();
        const initLoop = setInterval(() => {
            fetchStatus();
        }, checkInterval)

        return (() => {
            clearInterval(initLoop)
        })

        // eslint-disable-next-line
    }, [])

    const checkStatus = (res) => {
        if ((moment().unix() - moment(res.lastResponse).unix()) > 120) {
            setStatus(404)
        } else {
            setStatus(200)
        }
    }


    return (
        <StatusBlock
            loading={loading}
            name='Private API'
            status={status}
            lastResponse={data.lastResponse}
            lastCheck={lastCheck}
            checkInterval={checkInterval}
        />
    )
}

export default LocalAPI
