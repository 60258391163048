import React from 'react'
import WebsiteBlock from './Website'
import HaffhusEnergyBlock from './HaffhusEnergy'
import LocalAPIBlock from './LocalAPI'
import PublicAPIBlock from './PublicAPI'

const Blocks = () => {
    return (
        <div className={`blocks`}>

            <a href="https://www.haffhus.de" rel="noopener noreferrer" target="_blank">
                <WebsiteBlock />
            </a>

            <a href="https://energie.haffhus.de" rel="noopener noreferrer" target="_blank">
                <HaffhusEnergyBlock />
            </a>

            <LocalAPIBlock />

            <a href="https://www.haffhus.de/docs/energie-api" rel="noopener noreferrer" target="_blank">
                <PublicAPIBlock />
            </a>
        </div>
    )
}

export default Blocks
