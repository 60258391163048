import React from 'react'

const Legend = () => {

    const options = [
        'Online',
        'Offline',
        'Maintenance',
        'Unknown'
    ]

    return (
        <div className={`legend`}>
            {options.map((option, i) =>
                <div key={i} className={`option ${option.toLowerCase()}`}>
                    <span className={`indicator`}></span>
                    <span>{option}</span>
                </div>
            )}
        </div>
    )
}

export default Legend
